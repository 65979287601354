
export const saveDataAsFile = (data, mimeType, fileName) => {

    // Создаем Blob с текстовыми данными и нужным типом
    const blob = new Blob([data], { type: mimeType })

    // Создаем ссылку для скачивания
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    
    // Добавляем к имени файла нужное расширение
    link.download = fileName

    // Программно кликаем по ссылке, чтобы инициировать скачивание
    document.body.appendChild(link)
    link.click()

    // Удаляем ссылку после скачивания
    document.body.removeChild(link)
}