import { saveDataAsFile } from '@/helpers/File'
import { json2xml } from 'xml-js'

export default {
    methods: {
        exportToJson (reports, fileName) {

            if (!Array.isArray(reports))
                throw Error('Reports must be a type of Array')

            if (!reports.every(r => typeof r.value !== 'undefined'))
                throw Error('Each report must have a field "value"')

            const _reports = reports
                                .map(r => typeof r.value === 'string' ? JSON.parse(r.value) : r.value )

            const data = JSON.stringify(_reports, null, 2)

            saveDataAsFile(data, 'application/json', `${fileName}.json`)
        },
        exportToXml (reports, fileName) {

            if (!Array.isArray(reports))
                throw Error('Reports must be a type of Array')

            if (!reports.every(r => typeof r.value !== 'undefined'))
                throw Error('Each report must have a field "value"')

            const _reports = reports
                                .map(r => typeof r.value === 'string' ? JSON.parse(r.value) : r.value )

            const data = json2xml(JSON.stringify({ root: { report: _reports } }, null, 2), { compact: true, spaces: 4 })
                            .replaceAll('<не проид.>', '<Н>')
                            .replaceAll('</не проид.>', '</Н>')

            saveDataAsFile('<?xml version="1.0" encoding="UTF-8"?>' + data, 'application/xml', `${fileName}.xml`)
        }
    }
}